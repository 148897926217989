import React from 'react';

import { getRnrAuthEndpointId, getRnrPartnerId } from '@msdyn365-commerce-modules/retail-actions';
import {
    Alert, Button, INodeProps, Modal, ModalBody, ModalFooter, ModalHeader
} from '@msdyn365-commerce-modules/utilities';
import { Review } from '@msdyn365-commerce/commerce-entities';
import { RatingComponent } from '@msdyn365-commerce/components';
import { getUrlSync, IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { GetUserReviewInput } from '../actions/inputs/get-user-review-input';
import { SubmitUserReviewInput } from '../actions/inputs/submit-user-review-input';
import submitUserReview from '../actions/submit-user-review';

export interface IReviewModalProps {
    userReview?: Review;
    product?: SimpleProduct;
    renderContextId: string;
    resources: IReviewModalResources;
    context:  ICoreContext<IGeneric<IAny>>;
    parentId: string;
    parentType: string;
    isOpen: boolean;
    rating: number;
    reviewTitle: string;
    reviewText: string;
    returnRef: React.RefObject<HTMLButtonElement> | undefined;
    hasError: boolean;
    onModalToggle():void;
    onTitleChange(event: React.ChangeEvent<HTMLInputElement>):void;
    onTextChange(event: React.ChangeEvent<HTMLTextAreaElement>):void;
    onRatingChange(value: number):void;
    onReviewComplete?(isSuccess: boolean):void;
}

export interface IReviewModalResources {
    selectRatingAriaLabel: string;
    selectRatingLabel: string;
    reviewTitleLabel: string;
    reviewTextLabel: string;
    privacyPolicyTitle: string;
    privacyPolicyTextFormat: string;
    writeReviewModalTitle: string;
    editReviewModalTitle: string;
    submitReviewButtonText: string;
    discardReviewButtonText: string;
    errorMessageText: string;
}

export interface IReviewModalViewProps {
    modal: INodeProps;
    modalHeader: React.ReactElement;
    modalFooter: INodeProps;
    modalBody: INodeProps;
    cancelButton: React.ReactElement;
    submitButton: React.ReactElement;
    rating: React.ReactElement;
    ratingLabel: React.ReactElement;
    titleInput: React.ReactElement;
    titleLabel: React.ReactElement;
    textInput: React.ReactElement;
    textLabel: React.ReactElement;
    privacyPolicyUrl: React.ReactElement;
    form: INodeProps;
    inputRow: INodeProps;
    error: React.ReactElement;
}

/**
 * CartLineItems component
 */

export const ReviewModal = (props: IReviewModalProps):IReviewModalViewProps  => {
    const onClickSubmit = () => {return onSubmit(props);};
    return {
        modal: {tag: Modal, className: 'ms-review-modal', toggle: props.onModalToggle, applicationNode: 'renderPage', isOpen: props.isOpen, returnFocusRef: props.returnRef},
        modalHeader: modalHeaderNode(props),
        modalFooter: {tag: ModalFooter, className: 'ms-review-modal-footer'},
        modalBody: {tag: ModalBody, className: 'ms-review-modal-body'},
        cancelButton: <Button className='ms-review-modal-cancel' onClick={props.onModalToggle}>{props.resources.discardReviewButtonText}</Button>,
        submitButton: <Button className='ms-review-modal-submit' onClick={onClickSubmit} disabled={submitDisabled(props)}>{props.resources.submitReviewButtonText}</Button>,
        rating: ratingsInput(props),
        ratingLabel: ratingsLabel(props),
        titleInput: titleInput(props),
        titleLabel: titleLabel(props),
        textInput: textInput(props),
        textLabel: textLabel(props),
        privacyPolicyUrl: privacyPolicyLink(props),
        form: {tag: 'form', className: 'ms-review-modal-form', autoComplete: 'off'},
        inputRow: {tag: 'div', className: 'ms-review-modal-row', autoComplete: 'off'},
        error: <Alert className='ms-review-modal-error' isOpen={props.hasError} color='danger'>{props.resources.errorMessageText}</Alert>
    };
};

const modalHeaderNode = (props: IReviewModalProps) => {
    return (
        <ModalHeader toggle={props.onModalToggle} className='ms-review-modal-header'>
            { props.userReview && props.userReview.reviewId ? props.resources.editReviewModalTitle : props.resources.writeReviewModalTitle }
        </ModalHeader>
    );
};

const submitDisabled = (props: IReviewModalProps) => {
    return !props.rating || !stripWhiteSpacesFromString(props.reviewText) || !stripWhiteSpacesFromString(props.reviewTitle);
};

const ratingsInput = (props: IReviewModalProps) => {
    return (
        <RatingComponent
            readOnly={false}
            avgRating={0}
            ariaLabel={props.resources.selectRatingAriaLabel}
            initialRating={props.rating}
            context={props.context}
            id={props.parentId}
            typeName={props.parentType}
            data={{}}
            key='ratings'
            onChange={props.onRatingChange}
        />
    );
};

const ratingsLabel = (props: IReviewModalProps) => {
    return <label className='ms-review-modal-rating-label' key='ratingsLabel'>{props.resources.selectRatingLabel}*</label>;
};

const titleInput = (props: IReviewModalProps) => {
    const maxLength = props.context.app.config.reviewTitleMaxLength;

    return (
        <input
            maxLength={maxLength}
            id={`reviewTitle-${props.renderContextId}`}
            onChange={props.onTitleChange}
            value={props.reviewTitle}
            aria-required='true'
            className='ms-review-modal-title'
        />
    );
};

const titleLabel = (props: IReviewModalProps) => {
    return <label className='ms-review-modal-title-label' htmlFor={`reviewTitle-${props.renderContextId}`}>{props.resources.reviewTitleLabel}</label>;
};

const reviewTextWidth: number = 50;

const textInput = (props: IReviewModalProps) => {
    const maxLength = props.context.app.config.reviewTextMaxLength;
    return (
        <textarea
            maxLength={maxLength}
            className='ms-review-modal-text'
            id={`reviewTextArea-${props.renderContextId}`}
            onChange={props.onTextChange}
            rows={maxLength / reviewTextWidth}
            value={props.reviewText}
            aria-required='true'
        />
    );
};

const textLabel = (props: IReviewModalProps) => {
    return <label htmlFor={`reviewTextArea-${props.renderContextId}`} className='ms-review-modal-text-label'>{props.resources.reviewTextLabel}</label>;
};

const privacyPolicyLink = (props: IReviewModalProps) => {
    const policyTextBlocks = props.resources.privacyPolicyTextFormat.split('{0}');
    const isSeparatorAtStart = props.resources.privacyPolicyTextFormat.startsWith('{0}');
    const isSeparatorAtEnd = props.resources.privacyPolicyTextFormat.endsWith('{0}');
    const privacyPolicyUrl = getUrlSync('rnrPrivacyPolicy', props.context.actionContext);

    return (
        <p className='ms-review-modal-policy'>
            {isSeparatorAtStart ? '' : policyTextBlocks[0]}
            <a href={privacyPolicyUrl} target='_blank' className='msc-link'>{props.resources.privacyPolicyTitle}</a>
            {isSeparatorAtEnd ? '' : isSeparatorAtStart ? policyTextBlocks[0] : policyTextBlocks[1] }
        </p>
    );
};

const onSubmit = (props: IReviewModalProps): void => {
    if (props.product) {
        submitReview(props.product, props.reviewTitle, props.reviewText, props.rating, props.context, props.userReview, props.onReviewComplete);
    }
};

/**
 * This method strips content of extra white spaces
 * @param text The text to be stripped of extra white spaces
 */
const stripWhiteSpacesFromString = (text: string): string => {
    return text
        .replace(new RegExp('\\r?\\n|\\r+', 'g'), ' ')
        .replace(new RegExp('\\s+', 'g'), ' ')
        .trim();
};

export const submitReview = (product: SimpleProduct, title: string, text: string, rating: number, context: ICoreContext, userReview?: Review, onReviewSubmission?: (isSuccessful: boolean) => void) => {
    const { request } = context;
    const { user } = request;
    if (user && request) {
        const input = new SubmitUserReviewInput(
            product.RecordId.toString(),
            getRnrPartnerId(context.actionContext),
            request.apiSettings.channelId.toString(),
            request.locale,
            {
                productName: product && product.Name,
                rating: rating,
                reviewText: stripWhiteSpacesFromString(text),
                title: stripWhiteSpacesFromString(title),
                userEmail: user.emailAddress,
                userName: user.name
            },
            request.user.token,
            getRnrAuthEndpointId(context.actionContext)
        );

        submitUserReview(input, context.actionContext).then(reviewId => {
            const review = !!reviewId ? {
                rating: rating,
                title: stripWhiteSpacesFromString(title),
                reviewText: stripWhiteSpacesFromString(text),
                submittedDateTime: new Date(),
                helpfulPositive: userReview ? userReview.helpfulPositive : 0,
                helpfulNegative: userReview ? userReview.helpfulNegative : 0,
                reviewId: reviewId,
                userName: request.user.firstName || request.user.name
            } : userReview;

            const getReviewInput = new GetUserReviewInput(input.productId!, input.tenantId, input.authorization, input.serviceEndpoint);
            context.actionContext.update(getReviewInput, review);

            if (onReviewSubmission) {
                onReviewSubmission(!!reviewId);
            }
        }).catch(() => {
            const productId = product.RecordId;
            context.telemetry.error(`Failed to submit review for product with recordId: ${productId}`);
            if (onReviewSubmission) {
                onReviewSubmission(false);
            }
        });
    }
};