import React from 'react';

export const SignInMessage: React.FC<{text: string}> = (props: {text: string}) => {
    return <p className='ms-write-review__sign-in'>{props.text}</p>;
};

export const SignInButton: React.FC<{text: string; href: string; ariaLabel: string}> = (props: {text: string; href: string; ariaLabel: string}) => {
    const {text, href, ariaLabel} = props;
    return <a href={href} className='msc-btn' aria-label={ariaLabel}>{text}</a>;
};

interface IModalToggleProps {
    innerRef: React.RefObject<HTMLButtonElement> | undefined;
    id: string;
    text: string;
    ariaLabel: string;
    onClick():  void;
}

export const ModalToggle: React.FC<IModalToggleProps> = (props: IModalToggleProps) => {
    const {text, onClick, ariaLabel, innerRef, id} = props;
    return <button id={id} className='msc-btn' aria-label={ariaLabel} onClick={onClick} ref={innerRef}>{text}</button>;
};