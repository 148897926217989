import * as React from 'react';

import { Alert, format, Heading, INodeProps, Quantity } from '@msdyn365-commerce-modules/utilities';
import {
    AddToCartComponent,
    AddToWishlistComponent,
    IAddToCartFailureResult,
    IWishlistActionErrorResult,
    PriceComponent,
    RatingComponent
} from '@msdyn365-commerce/components';
import { getUrlSync } from '@msdyn365-commerce/core';
import { IBuyboxCallbacks, IBuyboxData, IBuyboxProps, IBuyboxResources, IBuyboxState } from '../../../index';
import { getConfigureErrors, getGenericError, getQuantityError } from '../utilities/error-utilities';

export function getBuyboxProductTitle(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined {
    const {
        data: { product: { result: product } },
        config: { titleHeadingTag = 'h1' }
    } = props;

    return product && (
        <Heading
            className='ms-buybox__product-title'
            headingTag={titleHeadingTag}
            text={product.Name || ''}
        />
    );
}

export function getBuyboxProductDescription(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined {
    const {
        data: { product: { result: product } }
    } = props;

    return product && (
        <p className='ms-buybox__product-description'>{product.Description}</p>
    );
}

export interface IBuyboxAddToCartViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxAddToCart(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxAddToCartViewProps {
    const {
        id,
        typeName,
        context,
        data: { cart: { result: cart }, product: { result: product }, productAvailableQuantity: { result: productAvailableQuantity} },
        resources
    } = props;

    const
        {
            quantity,
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct
        } = state;

    const onAddToCartFailed = (result: IAddToCartFailureResult) => {
        callbacks.updateErrorState({
            errorHost: 'ADDTOCART',
            quantityError: result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined,
            configureErrors: result.failureReason === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
            otherError: getGenericError(result, cart, resources, context)
        });
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-cart-container'
        },
        button: product && (
            <AddToCartComponent
                addToCartText={resources.addToCartText}
                outOfStockText={resources.outOfStockText}
                navigationUrl={getUrlSync('cart', context.actionContext)}
                quantity={quantity}
                data={{product: product}}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToCartFailed}
                getSelectedProduct={selectedProduct}
                productAvailability={state.productAvailableQuantity ? state.productAvailableQuantity : productAvailableQuantity}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'ADDTOCART'}
            />
        )
    };
}

export function getBuyboxProductPrice(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        data: { productPrice: { result: productPrice } },
        resources
    } = props;

    return productPrice && (
        <PriceComponent
            id={id}
            typeName={typeName}
            context={context}
            data={{price: productPrice}}
            freePriceText={resources.priceFree}
            originalPriceText={resources.originalPriceText}
            currentPriceText={resources.currentPriceText}
        />
    );
}

export function getBuyboxProductRating(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined  {
    const {
        id,
        typeName,
        context,
        data: { ratingsSummary: { result: ratingsSummary } },
        resources
    } = props;

    const ratingComponent = ratingsSummary && ratingsSummary.averageRating && (
        <RatingComponent
            avgRating={ratingsSummary.averageRating || 0}
            readOnly={true}
            ariaLabel={format(resources.averageRatingAriaLabel, ratingsSummary.averageRating, '5')}
            ratingCount={`${ratingsSummary.reviewsCount}`}
            data={{}}
            context={context}
            id={id}
            typeName={typeName}
        />
    ) || undefined ;

    return ratingsSummary && ratingComponent && (
        ratingComponent
        );
}

export interface IBuyboxAddToWishlistViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxProductAddToWishlist(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxAddToWishlistViewProps | undefined {
    const {
        id,
        typeName,
        context,
        data: {
            product: { result: product },
            wishlists: { result: wishlists }
        },
        resources
    } = props;

    const
        {
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct
        } = state;

    const onAddToWishlistFailed = (result: IWishlistActionErrorResult) => {
        callbacks.updateErrorState({
            errorHost: 'WISHLIST',
            configureErrors: result.status === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
        });
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-wishlist-container'
        },
        button: product && (
            <AddToWishlistComponent
                addToWishlistButtonText={resources.addToWishlistButtonText}
                removeFromWishlistButtonText={resources.removeFromWishlistButtonText}
                addToWishlistMessage={resources.addToWishlistMessage}
                removedFromWishlistMessage={resources.removedFromWishlistMessage}
                addItemToWishlistError={resources.addItemToWishlistError}
                removeItemFromWishlistError={resources.removeItemFromWishlistError}
                nameOfWishlist={resources.nameOfWishlist}
                data={{ product: product, wishlists: wishlists }}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToWishlistFailed}
                getSelectedProduct={selectedProduct}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'WISHLIST'}
            />
        )
    };
}

export interface IBuyboxProductQuantityViewProps {
    ContainerProps: INodeProps;
    LabelContainerProps: INodeProps;

    heading: React.ReactNode;
    errors?: React.ReactNode;

    input: React.ReactNode;
}
export function getBuyboxProductQuantity(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxProductQuantityViewProps {
    const {
        resources,
        context: {
            app: {
                config: {
                    maxQuantityForCartLineItem
                }
            }
        }
    } = props;

    const
        {
            quantity,
            errorState: {
                quantityError,
            }
        } = state;

    const onChange = (newValue: number) => {
        if (callbacks.updateQuantity) {
            callbacks.updateQuantity(newValue);
        }
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__quantity'
        },
        LabelContainerProps: {
            tag: 'label',
            className: 'ms-buybox__product-quantity-label',
            htmlFor: 'ms-buybox__product-quantity-input'
        },
        heading: (
            <div className='ms-buybox__product-quantity-label-heading'>{resources.productQuantityHeading}</div>
        ),
        errors: quantityError && (
            <span className='msc-alert msc-alert-noborder msc-alert-danger'>
                <span className='msi-exclamation-triangle' aria-hidden='true' />
                <span>{quantityError}</span>
            </span>
        ),
        input: (
            <Quantity
                id='ms-buybox__product-quantity-input'
                max={maxQuantityForCartLineItem || 10}
                currentCount={quantity}
                onChange={onChange}
                inputQuantityAriaLabel={resources.inputQuantityAriaLabel}
            />
        )
    };
}

export interface IBuyboxErrorBlockProps {
    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    otherError?: string;
    resources: IBuyboxResources;
    showError: boolean;
}
export const BuyboxErrorBlock: React.FC<IBuyboxErrorBlockProps> = ({ showError, configureErrors, quantityError, otherError, resources }) => {
    let errorMessages: (string | undefined)[] = [];

    errorMessages = Object.values(configureErrors).filter(message => message !== undefined);

    if (quantityError) {
        errorMessages.push(quantityError);
    }

    if (otherError) {
        errorMessages.push(otherError);
    }

    return (
        <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={resources.buyboxErrorMessageHeader} >
            <div className='msc-alert__header' aria-hidden='true'>
                <span className='msi-exclamation-triangle' />
                <span>{resources.buyboxErrorMessageHeader}</span>
            </div>
            {errorMessages.map((message) => {
                if (message) {
                    return (
                        <div className='msc-alert__line'>{message}</div>
                    );
                } else {
                    return null;
                }
            })}
        </Alert>
    );
};