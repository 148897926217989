import { IAddToCartFailureResult } from '@msdyn365-commerce/components';
import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { ProductDimension } from '@msdyn365-commerce/retail-proxy';
import { IBuyboxResources } from '../buybox.props.autogenerated';

export function getQuantityError(stockLeft: number | undefined, resources: IBuyboxResources): string | undefined {
    if (!stockLeft || stockLeft <= 0) {
        return resources.errorMessageOutOfStock;
    } else if (stockLeft === 1) {
        return resources.errorMessageOutOfRangeOneLeft;
    } else {
        return resources.errorMessageOutOfRangeFormat.replace('{numLeft}', stockLeft.toString());
    }
}

export function getGenericError(result: IAddToCartFailureResult, cart: ICartState | undefined, resources: IBuyboxResources, context: ICoreContext<IGeneric<IAny>>): string | undefined {
    if (result.failureReason === 'EMPTYINPUT') {
        return resources.addedToCartFailureMessage;
    } else if (result.failureReason === 'CARTACTIONFAILED') {
        if (result.cartActionResult && result.cartActionResult.substatus === 'MAXQUANTITY') {
            const quantityLimit = cart!.cart.ExtensionProperties?.find((item) => item.Key === 'quantityLimit')?.Value?.IntegerValue || 0;

            return resources.maxQuantityLimitText.replace('{quantity}', quantityLimit.toString());
        }

        return resources.addedToCartFailureMessage;
    }

    return undefined;
}

export function getConfigureErrors(result: ProductDimension[] | undefined, resources: IBuyboxResources):  { [configureId: string]: string | undefined } {
    if (!result) {
        return {};
    }

    const dimensions: { [configureId: string]: string | undefined } = {};

    result.forEach(dimension => {
        dimensions[dimension.DimensionTypeValue] = getDimensionErrorString(dimension.DimensionTypeValue, resources);
    });

    return dimensions;
}

export function getDimensionErrorString(dimensionTypeValue: number, resources: IBuyboxResources): string {
    switch (dimensionTypeValue) {
        case 1: // ProductDimensionType.Color
            return resources.productDimensionTypeColorErrorMessage;
        case 2: // ProductDimensionType.Configuration
            return resources.productDimensionTypeConfigurationErrorMessage;
        case 3: // ProductDimensionType.Size
            return resources.productDimensionTypeSizeErrorMessage;
        case 4: // ProductDimensionType.Style
            return resources.productDimensionTypeStyleErrorMessage;
        default:
            return '';
    }
}